import { LayoutModule } from '@angular/cdk/layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BlockUIModule } from 'ng-block-ui';
import { ToastrModule } from 'ngx-toastr';
import { MatPaginatorIntl, MAT_DATE_LOCALE } from '@angular/material';
import { getPtBrPaginatorIntl } from './utils/pt-br-paginator-intl';
import { AuthInterceptor } from './shared/guard/auth.interceptor';
import { NgxCurrencyModule } from 'ngx-currency';
import { BreadcrumbModule } from 'xng-breadcrumb';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';

registerLocaleData(localePt);

export const customCurrencyMaskConfig = {
  align: 'right',
  allowNegative: false,
  allowZero: true,
  decimal: ',',
  precision: 2,
  prefix: 'R$ ',
  suffix: '',
  thousands: '.',
  nullable: true,
};

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    OverlayModule,
    HttpClientModule,
    BlockUIModule.forRoot(),
    ToastrModule.forRoot({
      preventDuplicates: true,
    }),
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    BreadcrumbModule,
    NgxMaskModule.forRoot(options),
  ],
  providers: [
    {
      provide: MatPaginatorIntl,
      useValue: getPtBrPaginatorIntl(),
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: LOCALE_ID, useValue: 'pt' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
