import { Injectable, Inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { ConfirmacaoUsuarioSenha } from 'src/app/models/confirmacao-usuario-senha';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {}

  login(email: string, senha: string) {
    return this.http.post(`${this.baseUrl}/auth/login`, { email: email, senha: senha }).pipe(
      map((auth) => {
        if (auth) {
          localStorage.setItem('token', auth['token']);
          localStorage.setItem('refreshToken', auth['refreshToken']);
          return auth;
        }
        return null;
      }),
      catchError((e) => throwError(e))
    );
  }

  refreshToken() {
    const refreshToken = localStorage.getItem('refreshToken');
    return this.http.post(`${this.baseUrl}/auth/refresh`, { refreshToken }).pipe(
      map((response) => {
        localStorage.setItem('token', response['token']);
        localStorage.setItem('refreshToken', response['refreshToken']);
      })
    );
  }

  recuperarSenha(email: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/auth/recuperacao-senha`, { email });
  }

  cadastrarNovaSenha(confirmacaoUsuarioSenha: ConfirmacaoUsuarioSenha): Observable<any> {
    return this.http.post(`${this.baseUrl}/auth/nova-senha`, confirmacaoUsuarioSenha);
  }

  confirmarEmailUsuario(confirmacaoUsuarioSenha: ConfirmacaoUsuarioSenha): Observable<any> {
    return this.http.post(`${this.baseUrl}/auth/confirmacao-email`, confirmacaoUsuarioSenha);
  }

  roleMatch(allowedRoles: any[]) {
    let match = false;
    const token = localStorage.getItem('token');
    const str = this.atou(token.split('.')[1]);
    const payload = JSON.parse(str);
    allowedRoles.forEach((role) => {
      if (payload.role === role) {
        match = true;
        return false;
      }
    });

    return match;
  }

  atou(base64: string) {
    return decodeURIComponent(escape(atob(base64)));
  }
}
