import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { catchError, switchMap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router, private toastr: ToastrService, private authService: AuthService) {}

  readonly unauthorized = 401;
  isRefreshing = false;

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    const token = localStorage.getItem('token');

    if (token) {
      return next.handle(this.addToken(request)).pipe(
        catchError((error) => {
          if (error instanceof HttpErrorResponse && error.status === this.unauthorized) {
            return this.handle401Error(request, next);
          } else {
            return throwError(error);
          }
        })
      );
    }

    return next.handle(request.clone());
  }

  logout() {
    setTimeout(() => this.toastr.clear(), 100);
    this.isRefreshing = false;
    localStorage.clear();
    const returnUrl = this.router.routerState.snapshot.url;
    this.router.navigate(['/login'], { queryParams: { returnUrl: returnUrl } });
  }

  addToken(request: HttpRequest<any>) {
    const token = localStorage.getItem('token');
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      return this.authService.refreshToken().pipe(
        switchMap(() => {
          this.isRefreshing = false;
          return next.handle(this.addToken(request));
        }),
        catchError((e) => {
          this.logout();
          return throwError(e);
        })
      );
    } else {
      return next.handle(this.addToken(request));
    }
  }
}
